<template>
  <v-container>
    <v-card
      class="my-7 mx-auto"
      max-width="800"
    >
      <v-row class="ma-0">
        <v-col
          sm="5"
          cols="12"
          class="primary darken-1 pa-7 d-flex align-items-center rounded-left-xs"
        >
          <div class="ma-auto text-center white--text">
            <v-icon class="contact-icon primary--text darken-1">
              mdi-login
            </v-icon>
            <br />
            <div class="subtitle my-5">
              Accedi per confermare
            </div>
          </div>
        </v-col>
        <v-col
          sm="7"
          cols="12"
          class="px-7 py-3 d-flex"
        >
          <v-card
            class="ma-auto"
            elevation="0"
            :width="$vuetify.breakpoint.smAndUp ? '70%' : '85%'"
          >
            <v-form
              ref="form"
              class="text-center"
              @submit.prevent="confirmInvite"
            >
              <div class="headline secondary--text font-weight-bold my-2">
                Conferma invito
              </div>
              {{ invite.procedure.title }} di {{ invite.user.company.name }}
              <v-text-field
                v-model="user.email"
                label="Email *"
                type="email"
                class="my-5"
                required
                :rules="[formRules.required,formRules.emailRules]"
                disabled
              >
              </v-text-field>
              <v-text-field
                v-model="user.password"
                label="Password *"
                type="password"
                required
                :rules="[formRules.required]"
              >
              </v-text-field>
              <v-btn
                class="primary darken-1 white--text my-3"
                :loading="loading"
                @click.prevent="confirmInvite"
              >
                Conferma Invito
              </v-btn>
              <v-alert
                v-if="error"
                dense
                outlined
                color="error"
                class="my-5"
                dismissible
              >
                {{ error }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    user: {
      email: '',
      password: '',
      password2: '',
      name: '',
      surname: '',
      privacy: '',
      marketing: '',
    },
    error: null,
    rules: {
      required: value => !!value || 'Required.',
      emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
      cfRule: v => /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/.test(v) || 'not valid',
    },
    invite: {},
    loading: false,
  }),
  computed: {
    ...mapGetters(['formRules']),
  },
  mounted() {
    this.getInvite()
  },
  methods: {
    async getInvite() {
      if (this.$route.params.tk) {
        try {
          const response = await this.$api.readInvite(this.$route.params.tk)
          this.invite = response.data
          this.user.email = this.invite.target_user_email
        } catch (e) {
          console.log(e)
        }
      }
    },
    async confirmInvite() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await this.$api.registerAdd(this.$route.params.tk, this.user)
          await this.$store.dispatch('set_alert', { msg: 'Pratica collegata', type: 'success' })
          await this.$store.dispatch('login', this.user).then(async () => {
            await this.$store.dispatch('reload_all_data')

            setTimeout(async () => {
              await this.$router.push({ name: 'dashboard' })
            }, 500)
          }).catch(err => {
            if (err.response) {
              this.error = 'Errore, si prega di ritentare'
              this.loading = false
            }
          })
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: 'Errore, si prega di ritentare', type: 'error' })
          this.loading = false
        }
      }
    },
  },
}
</script>
<style>
.contact-icon {
    background: white;
    border-radius: 50%;
    padding: 10px;
    margin: 20px;
}
</style>
